<template>
  <p class="p-zensavvy">{{translations.second_step.title}}</p>

  <div class="d-flex gap-3 flex-wrap w-100">
    <div style="flex: 1;min-width: 200px;">
      <label class="p-zensavvy" style="font-size: 14px !important;">{{translations.second_step.first_name}}</label>
      <label class="zensavvy-error" style="font-size: 11px !important;" v-for="error in errors.first_name.$errors">
        {{ error.$message }}  
      </label>
      <input type="text" class="form-control mb-3 text-center" v-model="submission.first_name"/>
    </div>

    <div style="flex: 1;min-width: 200px;">
      <label class="p-zensavvy" style="font-size: 14px !important;">{{translations.second_step.last_name}}</label>
      <label class="zensavvy-error" style="font-size: 11px !important;" v-for="error in errors.last_name.$errors">
        {{ error.$message }}  
      </label>
      <input type="text" class="form-control text-center" v-model="submission.last_name"/>
    </div>
  </div>


  <!-------------------------------------------------------------------------------------->
  
  <label class="p-zensavvy mt-3" style="font-size: 14px !important;">{{translations.first_step.title}}</label>
  <label class="zensavvy-error" style="font-size: 11px !important;" v-for="error in errors.services.$errors">
    {{ error.$message }}  
  </label>

  <div class="options-container">
    <div class="option" v-for="(option, index) in services"
        :key="index" :class="{ selected: submission.services.includes(option.slug) }" @click="toggleOption(option.slug)">
            <span class="option-circle p-zensavvy">{{ getLabel(index) }}</span>
            <span class="option-text p-zensavvy">{{ option.name }}</span>
            <span v-if="submission.services.includes(index)" class="p-zensavvy">
                <i classs="fa-solid fa-check"></i>
            </span>
    </div>
  </div>

</template>

<script>
export default {
    inject: ['axios', 'locale', 'translations'],

    props: {
      modelValue: { type: Object, required: true },
      errors: Object
    },

    data: () => ({
      services: [],
    }),

    computed: {
      submission: {
        get() {
          return this.modelValue
        },

        set(value) {
          this.$emit('update:modelValue', value)
        }
      }
    },

    methods: {
        getServices() {
            this.axios.get(`/services?locale=${this.locale}`).then(r => {
                this.services = r.data.data
            })
        },

        toggleOption(slug) {
            if (this.submission.services.includes(slug)) {
                this.submission.services = this.submission.services.filter(i => i !== slug);
            } else {
                this.submission.services.push(slug);
            }
        },

        getLabel(index) {
            let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
            return labels[index]
        }
    },

    mounted() {
      this.getServices()
    },
}
</script>

<style lang="scss" scoped>


.submit-btn {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  background-color: #a44de5;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border: none;
  text-align: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-btn:hover {
  background-color: #9639d6;
}
</style>